import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useMediaQuery } from 'react-responsive'
import { VizContainer } from '../../styles/OverviewStyles'
import {
  ChartRow,
  Label,
  Bar,
  BarLabel,
  Baseline,
  Progress,
  Symbol
} from '../../styles/OverviewChartStyles'
import { BsFillExclamationDiamondFill } from 'react-icons/bs'
import { wrangleSp32Data } from '../../utils/helpers'
//import { group } from 'd3-array'

const Sp32OverviewChart = ({ data }) => {
  const { t } = useTranslation()

  const sp32Data = wrangleSp32Data(data)
  const matches = useMediaQuery({ query: '(max-width: 740px)' })

  return (
    <VizContainer>
      {sp32Data.map((array, i) => {
        const baseline = array[0].VALUE_NUM
        const mostRecentValue = array[array.length - 1].VALUE_NUM
        const lastYearsValue = array[array.length - 2].VALUE_NUM
        const antigen = array[0].DIMENSION2
        return (
          <React.Fragment key={antigen}>
            {matches ? <Label>{t(`${antigen.toUpperCase()}`)}</Label> : null}
            <ChartRow>
              {!matches ? <Label>{t(`${antigen.toUpperCase()}`)}</Label> : null}
              <Bar
                role="img"
                alt={`${t(
                  'horizontal bar chart showing coverage'
                )} ${mostRecentValue}% ${t(
                  'in the 20% of districts with lowest coverage of'
                )} ${array[0].antigen}}`}
              >
                <Baseline left={baseline} />
                <Progress
                  progress={mostRecentValue}
                  color={
                    mostRecentValue > lastYearsValue
                      ? 'mint'
                      : mostRecentValue < lastYearsValue
                      ? 'yellow'
                      : 'gray'
                  }
                >
                  <BarLabel>{mostRecentValue.toFixed()}%</BarLabel>
                </Progress>
              </Bar>
              <Symbol>
                <BsFillExclamationDiamondFill color={'transparent'} size={42} />
              </Symbol>
            </ChartRow>
          </React.Fragment>
        )
      })}
    </VizContainer>
  )
}

export default Sp32OverviewChart
