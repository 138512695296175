import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useMediaQuery } from 'react-responsive'
import { PATHOGEN_LABELS_API } from '../../utils/const'
import { wrangleIg13Data, findTrendLine } from '../../utils/helpers'
import { VizContainer } from '../../styles/OverviewStyles'
import {
  ChartRow,
  Label,
  Bar,
  Baseline,
  Progress,
  BarLabel,
  Symbol,
  BaselineLabel,
  IconContainer,
  LeftArrow,
  ArrowHead,
  ArrowContainer
} from '../../styles/OverviewChartStyles'
import { BsFillExclamationDiamondFill } from 'react-icons/bs'
import { IoCheckmarkCircle } from 'react-icons/io5'
//import { group } from 'd3-array'

const Ig13OverviewChart = ({ data }) => {
  const { t } = useTranslation()

  const ig13Data = wrangleIg13Data(data)

  //need highest num for scale
  const findHighestValue = () => {
    const allValues = Object.entries(ig13Data)
      .map(([key, value]) =>
        value.map((item) => (item.DIMENSION2 !== null ? item.VALUE_NUM : null))
      )
      .flat()
    return Math.max(...allValues)
  }

  const scale = findHighestValue()
  const matches = useMediaQuery({ query: '(max-width: 740px)' })
  const order = [
    'CHOLERA',
    'EBOLA',
    'MEASLES',
    'MENINGOCOCCUS',
    'POLIO_VAC',
    'POLIO_WILD',
    'YFEVER'
  ]
  return (
    <VizContainer>
      {order.map((key, i) => {
        const sorted = ig13Data[key].sort((a, b) => (a.year > b.year ? 1 : -1))
        const baseline = sorted[0].VALUE_NUM
        const currValue = sorted[sorted.length - 1].VALUE_NUM

        const trendData =
          sorted[sorted.length - 1].year >= 2021 ? findTrendLine(sorted) : null

        const direction = trendData
          ? trendData[1] < trendData[3]
            ? 'increasing'
            : trendData[1] > trendData[3]
            ? 'decreasing'
            : null
          : null

        return (
          <React.Fragment key={`${key}-1`}>
            {matches ? <Label>{t(PATHOGEN_LABELS_API[key])}</Label> : null}
            <ChartRow key={key}>
              {!matches ? <Label>{t(PATHOGEN_LABELS_API[key])}</Label> : null}
              <Bar
                role="img"
                alt={`${t('horizontal bar chart showing')} ${currValue} ${t(
                  'outbreaks in'
                )} ${sorted[sorted.length - 1].year}. `}
              >
                <Baseline left={(baseline / scale) * 100} />
                <Progress
                  progress={
                    currValue > 0 ? Math.min((currValue / scale) * 100, 100) : 0
                  }
                  color={
                    trendData
                      ? !direction
                        ? 'gray'
                        : direction === 'increasing'
                        ? 'darkOrange'
                        : 'brightBlue'
                      : 'untracked'
                  }
                >
                  <BarLabel noPadding={(currValue / scale) * 100 < 3}>
                    {currValue}
                  </BarLabel>
                </Progress>
              </Bar>
              <Symbol>
                {direction && currValue >= 0 ? (
                  direction === 'decreasing' ? (
                    <IoCheckmarkCircle
                      color={'#4681de'}
                      size={42}
                      role="img"
                      alt="on target"
                    />
                  ) : (
                    <BsFillExclamationDiamondFill
                      color={'#d96038'}
                      size={42}
                      role="img"
                      alt="off target"
                    />
                  )
                ) : (
                  //this is invisible placeholder so spacing between bars comes out right
                  <IoCheckmarkCircle color={'transparent'} size={42} />
                )}
              </Symbol>
            </ChartRow>
          </React.Fragment>
        )
      })}
      <ChartRow marginTop={'-1rem'}>
        {!matches ? <Label></Label> : null}
        <IconContainer position={'flex-start'}>
          <BaselineLabel
            left={(ig13Data['YFEVER'][0].VALUE_NUM / scale) * 100 + 2.5}
            offset={'-20px'}
          >
            {t('number of large or disruptive outbreaks at baseline')}
          </BaselineLabel>
          <ArrowContainer
            left={(ig13Data['YFEVER'][0].VALUE_NUM / scale) * 100}
          >
            <ArrowHead />
            <LeftArrow></LeftArrow>
          </ArrowContainer>
        </IconContainer>
        <Symbol></Symbol>
      </ChartRow>
    </VizContainer>
  )
}

export default Ig13OverviewChart
