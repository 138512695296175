import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useMediaQuery } from 'react-responsive'
import { NUM_YEARS } from '../../utils/const'
import { VizContainer } from '../../styles/OverviewStyles'
import {
  ChartRow,
  Label,
  Bar,
  Baseline,
  Progress,
  Target,
  Symbol,
  IconContainer,
  LeftArrow,
  ArrowHead,
  BaselineLabel,
  BarLabel,
  ArrowContainer
} from '../../styles/OverviewChartStyles'
import { BsFillExclamationDiamondFill } from 'react-icons/bs'
import { IoCheckmarkCircle } from 'react-icons/io5'
import { format } from 'd3-format'

const Ig11OverviewChart = ({ data, regional }) => {
  const { t } = useTranslation()
  // const wrangleIg11 = (data) => {
  //   const allYears = []
  //   const total = Object.entries(data)
  //     .map(([key, value]) => {
  //       const keysToExclude = ['memberState', 'iSO3', 'id', 'incomeGroup']
  //       if (!keysToExclude.includes(key) && value !== null) {
  //         const year = +key.slice(-4)
  //         // eliminate 2019 and 2020 data since counting starting at 2021
  //         if (year >= 2021) {
  //           allYears.push(year)
  //           return { year, value }
  //         } else return null
  //       } else return null
  //     })
  //     .filter((item) => item)
  //     .reduce(
  //       (acc, d) => {
  //         acc['total'] += d.year >= 2021 ? d.value : 0 //count starting at 2021
  //         return acc
  //       },
  //       { total: 0 }
  //     )
  //   return { ...total, ...{ numYears: allYears.length } }
  // }
  const wrangleIg11 = (data) => {
    const total = data.reduce((sum, d) => {
      sum += d.YEAR >= 2021 ? d.VALUE_NUM : 0
      return sum
    }, 0)
    const numYears = data.length - 1 //not counting 2019
    return { total, numYears }
  }

  const ig11Data = wrangleIg11(data)
  const goal = 50000000
  const goalPerYear = goal / NUM_YEARS
  const TotalNeededToBeOnTrack = ig11Data.numYears * goalPerYear
  const onTarget = ig11Data.total >= TotalNeededToBeOnTrack
  const isSmall = useMediaQuery({ query: '(max-width: 740px)' })
  //const isXSmall = useMediaQuery({ query: '(max-width: 450px)' })

  const formatMill = format('.0s')
  const totalLabel = formatMill(ig11Data.total)
  //const currYear = ig11Data.numYears + 2020 //use in text label
  return (
    <VizContainer>
      {isSmall ? (
        <Label>{t('cumulative number of deaths averted')}</Label>
      ) : null}

      <ChartRow>
        {!isSmall ? (
          <Label>{t('cumulative number of deaths averted')}</Label>
        ) : null}

        <Bar
          //rounded={regional ? false : true}
          //right={true}
          mr={regional ? null : '2.9rem'}
          role="img"
          alt={`${t('horizontal bar chart showing progress of')} ${
            ig11Data?.total
          } ${t('towards goal of 50 million.')}`}
        >
          <Baseline left={(TotalNeededToBeOnTrack / goal) * 100} />
          <Progress
            progress={Math.min((ig11Data?.total / goal) * 100, 100)}
            color={regional ? 'gray' : onTarget ? 'brightBlue' : 'darkOrange'}
            // rounded={
            //   regional ? false : ig11Data?.total / goal > 97 ? true : false
            // }
            // right={true}
          >
            {' '}
            <BarLabel>{totalLabel}</BarLabel>
          </Progress>
          {regional ? null : (
            <Target
              right={'-2.9rem'}
              success={ig11Data.total >= goal}
              //adjust={true}
            >
              50M
            </Target>
          )}
        </Bar>
        <Symbol>
          {regional ? null : ig11Data.total ? (
            onTarget ? (
              <IoCheckmarkCircle
                color={'#4681de'}
                size={42}
                role="img"
                alt="on target"
              />
            ) : (
              <BsFillExclamationDiamondFill
                color={'#d96038'}
                size={42}
                role="img"
                alt="off target"
              />
            )
          ) : null}
        </Symbol>
      </ChartRow>
      <ChartRow marginTop={'-1.25rem'}>
        {!isSmall ? <Label></Label> : null}

        {/* adjusting by 2.9 to compensate for room target is taking up since bar does not extend full width underneath target. */}
        <IconContainer
          position={'flex-start'}
          marginRight={'2.9rem'}
          widthSm={'65%'}
          widthXs={'54%'}
        >
          <ArrowContainer left={(TotalNeededToBeOnTrack / goal) * 100 + 2.5}>
            <ArrowHead />
            <LeftArrow />
          </ArrowContainer>
          <BaselineLabel
            left={(TotalNeededToBeOnTrack / goal) * 100 + 5}
            offset={'-23px'}
          >
            {t(
              'to be on track for 2030 target, cumulative future deaths averted globally should reach here'
            )}
            &nbsp;
          </BaselineLabel>
        </IconContainer>
        <Symbol></Symbol>
      </ChartRow>
    </VizContainer>
  )
}

export default Ig11OverviewChart
