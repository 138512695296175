import React from 'react'

export const PointerIcon = ({ color }) => {
  return (
    <svg
      data-name="Explore data button"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <circle
        data-name="Ellipse 371"
        cx="12"
        cy="12"
        r="12"
        fill={`${color}`}
      />
      <g data-name="Group 1274">
        <path
          data-name="Path 1478"
          d="M1 0 .562 3.652 0 8.347"
          transform="rotate(45 -5.909 24.296)"
          stroke={'#fff'}
          strokeLinecap={'round'}
          strokeWidth={'2px'}
          fill={color}
          // style={
          //   {`stroke:#fff;stroke-linecap:round;stroke-width:2px;fill:${color}`}
          // }
        />
        <path
          data-name="Line 2006"
          transform="rotate(45 -2.234 15.427)"
          //style="fill:none;stroke:#fff;stroke-linecap:round;stroke-width:2px"
          fill={'none'}
          stroke={'#fff'}
          strokeLinecap={'round'}
          strokeWidth={'2px'}
          d="M8.347 0 0 1"
        />
      </g>
    </svg>
  )
}
