import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useMediaQuery } from 'react-responsive'
import { VizContainer } from '../../styles/OverviewStyles'
import {
  ChartRow,
  Label,
  Bar,
  BarLabel,
  Baseline,
  Progress,
  Symbol
} from '../../styles/OverviewChartStyles'
import { BsFillExclamationDiamondFill } from 'react-icons/bs'
//import { wrangleSP16Data } from '../../utils/helpers'
//import { NUM_COUNTRIES_IN_REG, GBL_NUM_COUNTRIES } from '../../utils/const'

const Sp16OverviewChart = ({ data, region }) => {
  const { t } = useTranslation()
  data.sort((a, b) => (a.YEAR > b.YEAR ? 1 : -1))
  //const numCountries = region ? NUM_COUNTRIES_IN_REG[region] : GBL_NUM_COUNTRIES //number of countries
  //const sp16Data = wrangleSP16Data(data)
  const baseline = data[0].VALUE_NUM
  const mostRecentValue = data[data.length - 1].VALUE_NUM
  const lastYearsValue =
    data.length > 1 ? data[data.length - 2].VALUE_NUM : baseline

  // const baselinePercent = (baseline / numCountries) * 100
  // const mostRecentPercent = (mostRecentValue / numCountries) * 100
  // const lastYearsPercent = (lastYearsValue / numCountries) * 100
  const matches = useMediaQuery({ query: '(max-width: 740px)' })
  return (
    <VizContainer>
      <ChartRow>
        {/* spacing placeholder */}
        {!matches ? <Label></Label> : null}
        <Bar
          role="img"
          alt={`${t(
            'horizontal bar chart showing'
          )} ${mostRecentValue.toFixed()} ${t(
            '% of countries have at least 1 documented individual serious AEFI case safety report per million total population.'
          )}`}
        >
          <Baseline left={baseline} />
          <Progress
            progress={mostRecentValue}
            color={
              mostRecentValue > lastYearsValue
                ? 'mint'
                : mostRecentValue < lastYearsValue
                ? 'yellow'
                : 'gray'
            }
          >
            <BarLabel>{`${mostRecentValue.toFixed()}%`}</BarLabel>
          </Progress>
        </Bar>
        <Symbol>
          <BsFillExclamationDiamondFill color={'transparent'} size={42} />
        </Symbol>
      </ChartRow>
    </VizContainer>
  )
}

export default Sp16OverviewChart
