import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useMediaQuery } from 'react-responsive'
import {
  PATHOGEN_LABELS_API
  // NUM_COUNTRIES_IN_REG,
  // GBL_NUM_COUNTRIES
} from '../../utils/const'
import { VizContainer } from '../../styles/OverviewStyles'
import {
  ChartRow,
  Label,
  Bar,
  Progress,
  BarLabel,
  // Blank,
  Symbol,
  BaselineLabel,
  IconContainer,
  Box
  //RightArrow,
  //ArrowHead
} from '../../styles/OverviewChartStyles'
//import { BsFillExclamationDiamondFill } from 'react-icons/bs'
import { IoCheckmarkCircle } from 'react-icons/io5'
import { wrangleIg12Data } from '../../utils/helpers'
//import { group } from 'd3-array'

const Ig12OverviewChart = ({ data, region }) => {
  const { t } = useTranslation()

  const ig12Data = wrangleIg12Data(data)

  const matches = useMediaQuery({ query: '(max-width: 740px)' })

  return (
    <VizContainer>
      <>
        {ig12Data.map((value) => {
          //enought data to calculate on track/off track, i.e. we're at 2022? b/c 2021 is baseline here
          // const sufficientData = value.length > 2
          // const prevAchieved = sufficientData
          // ? value[value.length - 2].Achieved //+
          // : //value[value.length - 2].NewlyAchieved
          //  value[value.length - 1].Achieved // +
          // value[value.length - 1].NewlyAchieved
          const currAchieved = value[value.length - 1].Achieved //+
          //value[value.length - 1].NewlyAchieved
          //const progress = currAchieved > prevAchieved
          //const stable = sufficientData ? currAchieved === prevAchieved : null
          const scale = value[value.length - 1].denominator //region
          //? NUM_COUNTRIES_IN_REG[region]
          // : GBL_NUM_COUNTRIES //number of countries

          // const notEndorsed = value[value.length - 1].NotEndorsed
          return (
            <React.Fragment key={`${value[0].pathogen}-1`}>
              {matches ? (
                <Label>{PATHOGEN_LABELS_API[value[0].pathogen]}</Label>
              ) : null}
              <ChartRow key={value[0].pathogen}>
                {!matches ? (
                  <Label>{t(PATHOGEN_LABELS_API[value[0].pathogen])}</Label>
                ) : null}
                <Bar
                  role="img"
                  alt={`${t(
                    'horizontal bar chart showing that'
                  )} ${currAchieved} ${t(
                    'countries have achieved their target out of'
                  )} ${scale} ${t('who have endorsed a target in')} ${
                    value[value.length - 1].year
                  }.`}
                >
                  <Progress
                    progress={Math.min((currAchieved / scale) * 100, 100)}
                    color={'baseBlue'}
                    //if we decide to show progress again, reimplement this
                    // color={
                    //   stable
                    //     ? 'gray'
                    //     : sufficientData
                    //     ? progress
                    //       ? 'brightBlue'
                    //       : 'darkOrange'
                    //     : 'untracked'
                    // }
                  >
                    <BarLabel>{currAchieved}</BarLabel>
                  </Progress>

                  {/* {notEndorsed > 0 && (
                    <Blank width={`${(notEndorsed / scale) * 100}%`} />
                  )} */}
                </Bar>
                <Symbol>
                  {/* this is invisible placeholder so spacing between bars comes out right */}
                  <IoCheckmarkCircle color={'transparent'} size={42} />
                </Symbol>
              </ChartRow>
            </React.Fragment>
          )
        })}
        <ChartRow marginTop={0}>
          {!matches && <Label></Label>}

          <IconContainer
            position="flex-start"
            alignItems={'flex-end'}
            marginTop={'4px'}
            height={'unset'}
          >
            <Box color={`var(--baseBlue)`} />
            <BaselineLabel left={8} wrap={'nowrap'}>
              {t('countries that have')} <strong>{t('achieved')}</strong>{' '}
              {t('target')}
            </BaselineLabel>
          </IconContainer>
          <Symbol></Symbol>
        </ChartRow>
        {/* <ChartRow marginTop={0}>
          {!matches && <Label></Label>}

          <IconContainer
            position="flex-start"
            alignItems={'flex-end'}
            marginTop={'4px'}
            height={'unset'}
          >
            <Box color={`var(--gray)`} />
            <BaselineLabel left={8} wrap={'nowrap'}>
              {t('countries that have')} <strong>{t('not achieved')}</strong>{' '}
              {t('target')}
            </BaselineLabel>
          </IconContainer>
          <Symbol></Symbol>
        </ChartRow> */}
        {/* <ChartRow marginTop={0}>
          {!matches && <Label></Label>}

          <IconContainer
            position="flex-start"
            alignItems={'flex-end'}
            marginTop={'4px'}
            height={'unset'}
          >
            <Blank width={'40px'} height={'25px'} />
            <BaselineLabel left={8} wrap={'nowrap'}>
              {t('countries with')} <strong>{t('no endorsed target')}</strong>
            </BaselineLabel>
          </IconContainer>
          <Symbol></Symbol>
        </ChartRow> */}
      </>
    </VizContainer>
  )
}

export default Ig12OverviewChart
