import styled from 'styled-components'
import { MediaQueries as Mq } from './MediaQueries'

export const ChartRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0.15rem 0;
  margin-top: ${(props) => props.marginTop};
`
export const Label = styled.div`
  font-size: 1.375rem;
  color: var(--darkGray);
  text-align: right;
  width: 35%;
  margin-right: 0.75rem;
  padding-left: 1rem;
  ${Mq.md} {
    font-size: 1.25rem;
  }
  ${Mq.sm} {
    width: 100%;
    font-size: 1.1rem;
    text-align: left;
    margin: 0;
    padding: 0.5rem 0 0 0;
  }
`
export const Bar = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between; //
  background-color: var(--lightestGray);

  height: 2.625rem;
  //these are for bars that don't yet have accomodation for Target badge. So far, IG1.1 and IG3.1 have this.
  border-radius: ${(props) =>
    props.rounded
      ? props.right
        ? '0 1.3rem 1.3rem 0'
        : '1.3rem 0  0 1.3rem '
      : 'none'};
  //accomodation for Target badge in IG1.1, IG2.2 and IG3.1.
  width: ${(props) => (props.mr ? `calc(65% - ${props.mr})` : '65%')};
  margin-right: ${(props) => props.mr};
  ${Mq.sm} {
    width: ${(props) => (props.mr ? '72%' : '80%')};
  }
  ${Mq.xs} {
    width: ${(props) => (props.mr ? '62%' : '80%')};
  }
`

export const Baseline = styled.div`
  position: absolute;
  top: -20%;
  left: ${(props) => (props.left ? `${props.left}%` : 0)};
  width: 3px;
  height: 140%;
  border-left: 3px solid var(--darkGray);
  border-radius: 3px;
  z-index: 5;
`
export const Progress = styled.div`
  width: ${(props) => `${props.progress}%`};
  background-color: ${(props) => `var(--${props.color})`};
  position: relative;
`
export const BarLabel = styled.div`
  position: absolute;
  right: ${({ noPadding }) => (noPadding ? 0 : '0.3rem')};
  left: ${({ left }) => (left ? '.5rem' : 'unset')};
  top: 0.7rem;
  color: var(--white);
  font-family: 'Bebas Neue';
  font-size: 1rem;
  //for 3.1:
  padding-right: ${({ currValue }) => (currValue > 80 ? '.8rem' : null)};
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
  ${Mq.lg} {
    top: 0.8rem;
    font-size: 0.9rem;
    right: ${({ custom }) => (custom === 'ig21' ? 0 : null)};
  }
  ${Mq.md} {
    right: ${({ custom }) => (custom === 'ig21' ? '.3rem' : null)};
  }
  ${Mq.sm} {
    top: 0.9rem;
    font-size: 0.75rem;
  }
  ${Mq.xs} {
    right: ${({ custom }) => (custom === 'ig21' ? 0 : null)};
  }
`
export const Blank = styled.div`
  width: ${(props) => `${props.width}`};
  height: ${(props) => `${props.height}`};
  outline: 1px solid var(--lightestGray);
  outline-offset: -1px;
  background: linear-gradient(
    to top left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 0.8px),
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0) calc(50% + 0.8px),
    rgba(0, 0, 0, 0) 100%
  );
  background-color: var(--white);
`
//for progress bars that don't start from left, i.e. 2.1 and 3.2
export const ProgressVariableStart = styled.div`
  position: absolute;
  left: ${(props) => `${props.left}%`};
  width: ${(props) => `${props.progress}%`};
  background-color: ${(props) => `var(--${props.color})`};
  height: 100%;
  border-radius: ${(props) =>
    props.rounded
      ? props.right
        ? '0 1.3rem 1.3rem 0'
        : '1.3rem 0  0 1.3rem '
      : 'none'};
`

export const Target = styled.div`
  position: absolute;
  right: ${(props) => props.right};
  top: -15%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Bebas Neue';
  font-size: 1.75rem;
  font-weight: normal;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;

  background-color: ${({ success }) =>
    success ? `var(--brightBlue)` : `var(--lightestGray)`};
  color: ${({ success }) => (success ? `var(--white)` : `var(--darkGray)`)};
  padding-left: ${({ adjust }) => (adjust ? '8px' : 0)};
`
export const Symbol = styled.div`
  width: 10%;
  padding: 0.5rem 0 0 0.6rem;
`
export const IconContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: ${(props) => props.position};
  align-items: ${(props) => props.alignItems};
  margin-top: 0.75rem;
  margin-top: ${(props) => props.marginTop};
  margin-right: ${(props) => props.marginRight};
  width: 65%;
  height: 2.625rem;
  height: ${(props) => props.height};
  ${Mq.sm} {
    width: 80%;
    width: ${(props) => props.widthSm};
  }
  ${Mq.xs} {
    width: 80%;
    width: ${(props) => props.widthXs};
  }
`
export const Box = styled.div`
  width: 40px;
  height: 25px;
  background-color: ${(props) => props.color};
`
export const BaselineLabel = styled.div`
  position: absolute;
  bottom: 3px;
  right: ${(props) => `${props.right}%`};
  left: ${(props) => `calc(${props.left}% + 20px)`};
  font-size: 16px;
  color: var(--gray);
  bottom: ${({ offset }) => `${offset}`};
  white-space: ${(props) => props.wrap};
  line-height: 1.3;
  width: 100%;
  width: ${(props) => props.width};
  ${Mq.md} {
    font-size: 0.8rem;
  }
  // ${Mq.sm} {
  //   bottom: ${({ offset }) => `${offset}`};
  // }
`

export const RightArrow = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  border: solid 3px var(--gray);
  border-color: transparent transparent var(--gray) transparent;
  border-radius: 50%;
  margin-right: -2px;
  transform: rotate(-45deg);
`
export const ArrowHead = styled.div`
  position: absolute;
  right: ${(props) => (props.rightArrow ? '-8px' : '40px')};
  top: 10px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 16px solid var(--gray);
  transform: rotate(-180deg);
`
export const LeftArrow = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  border: solid 3px var(--gray);
  border-color: transparent transparent transparent var(--gray);
  border-radius: 50%;
  margin-left: ${(props) => `${props.left}%`};

  transform: rotate(-45deg);
  ${Mq.sm} {
    margin-left: ${(props) => `calc(${props.left}% + 3px)`};
  }
`
export const ArrowContainer = styled.div`
  position: relative;
  margin-left: ${(props) => `${props.left}%`};
  margin-right: ${(props) => `${props.right}%`};
`
export const NoData = styled.div`
  font-family: 'Bebas Neue';
  font-size: 1.25rem;
  color: var(--darkGray);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`
