import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useMediaQuery } from 'react-responsive'
import { NUM_YEARS } from '../../utils/const'
import { VizContainer } from '../../styles/OverviewStyles'
import {
  ChartRow,
  Label,
  Bar,
  BarLabel,
  Baseline,
  ProgressVariableStart,
  Target,
  Symbol,
  IconContainer,
  ArrowHead,
  BaselineLabel,
  ArrowContainer,
  RightArrow
} from '../../styles/OverviewChartStyles'
//import { wrangle21Data } from '../../utils/helpers'
import { BsFillExclamationDiamondFill } from 'react-icons/bs'
import { IoCheckmarkCircle } from 'react-icons/io5'

const Ig21OverviewChart = ({ data }) => {
  const { t } = useTranslation()
  data.sort((a, b) => a.YEAR - b.YEAR)
  const allYearsToBeCounted = data.length - 1 //don't count 2019
  const baseline = data[0].VALUE_NUM
  const goal = baseline / 2
  const mostRecentValue = data[data.length - 1].VALUE_NUM
  const delta = mostRecentValue - baseline
  const scale =
    mostRecentValue >= baseline ? goal + (mostRecentValue - baseline) : goal
  const goalPerYear = goal / NUM_YEARS
  const TotalNeededToBeOnTrack = -(allYearsToBeCounted * goalPerYear)
  const onTarget = delta > 0 ? false : delta <= TotalNeededToBeOnTrack
  //if mostRecentValue  < baseline, left is 100% and scale is baseline/2
  //else if mostRecentValue > baseline, scale is baseline/2 + (mostRecentValue - baseline) which is the backslide amount,
  //then Baseline is at left: 100% - scaled backslide, so (mostRecentValue - baseline)/scale * 100

  const calcBaselinePos = () => {
    if (delta < 0) return 100
    else return 100 - (delta / scale) * 100
    //else return 100 - (Math.abs(delta) / scale) * 100
  }

  const calcProgressBarStart = () => {
    const base = calcBaselinePos()
    return base !== 100 ? base : 100 - (Math.abs(delta) / scale) * 100
  }
  const matches = useMediaQuery({ query: '(max-width: 740px)' })

  return (
    <VizContainer>
      {matches ? (
        <Label>{t('current progress on zero dose children')}</Label>
      ) : null}
      <ChartRow>
        {!matches ? (
          <Label>{t('current progress on zero dose children')}</Label>
        ) : null}
        <Bar
          rounded={true}
          right={false}
          role="img"
          alt={`${t('horizontal bar chart showing progress of')} ${(
            (Math.abs(delta) / scale) *
            100
          ).toFixed()} ${t(
            '% toward goal of 50% reduction in zero dose children.'
          )}`}
        >
          <Baseline left={calcBaselinePos()} />
          <ProgressVariableStart
            left={Math.max(calcProgressBarStart(), 0)} //don't let left have negative start
            progress={Math.min((Math.abs(delta) / scale) * 100, 100)} //don't let bar exceed 100%
            color={
              data[data.length - 1].YEAR >= 2021
                ? onTarget
                  ? 'brightBlue'
                  : 'darkOrange'
                : 'untracked'
            } //only show on/off track color is at least 2021
            rounded={(Math.abs(delta) / scale) * 100 > 97 ? true : false} // so that corners don't poke out behind target circle if progress exceeds 97%
            right={false} //rounds the left end of the bar
          >
            <BarLabel left={delta > 0 ? false : true} custom={'ig21'}>
              {delta > 0 ? '+' : ''}
              {`${((delta / baseline) * 100).toFixed()}%`}
            </BarLabel>
          </ProgressVariableStart>
          <Target>-50%</Target>
        </Bar>
        <Symbol>
          {data.length >= 3 ? (
            onTarget ? (
              <IoCheckmarkCircle
                color={'#4681de'}
                size={42}
                role="img"
                alt="on target"
              />
            ) : (
              <BsFillExclamationDiamondFill
                color={'#d96038'}
                size={42}
                role="img"
                alt="off target"
              />
            )
          ) : (
            <BsFillExclamationDiamondFill color={'transparent'} size={42} /> //placeholder for spacing
          )}
        </Symbol>
      </ChartRow>
      <ChartRow marginTop={'-1.25rem'}>
        {!matches ? <Label></Label> : null}

        <IconContainer position={'flex-end'}>
          <ArrowContainer right={100 - calcBaselinePos()}>
            <ArrowHead rightArrow={true} />
            <RightArrow />
          </ArrowContainer>
          <BaselineLabel
            right={100 - calcBaselinePos() + 5}
            offset={'-15px'}
            //wrap={'noWrap'}
            width={'unset'}
          >
            {t('baseline measure of 13M as of 2019')}
            &nbsp;
          </BaselineLabel>
        </IconContainer>
        <Symbol></Symbol>
      </ChartRow>
    </VizContainer>
  )
}

export default Ig21OverviewChart
