import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import {
  ExploreContainer,
  ExploreLabel,
  IconBox,
  PointerLink
} from '../styles/OverviewStyles'
import { PointerIcon } from '../components/PointerIcon'

const Explore = ({ goTo }) => {
  const { t } = useTranslation()
  return (
    <ExploreContainer>
      <PointerLink to={`${goTo}`}>
        <ExploreLabel>{t('explore data')}</ExploreLabel>
        <IconBox>
          <PointerIcon color={'inherit'} />
        </IconBox>
      </PointerLink>
    </ExploreContainer>
  )
}

export default Explore
